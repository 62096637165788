<template>
  <v-menu
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-content-click="false"
    :nudge-width="300"
    top
    offset-x
  >
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom>
        <template #activator="{on: onTooltip, attrs: attrsTooltip}">
          <!-- activator command button -->
          <v-btn
            v-bind="{ ...attrsMenu, ...attrsTooltip }"
            v-on="{ ...onMenu, ...onTooltip }"
            fab
            small
            :outlined="buttonOutlined"
            :text="buttonRecommendedTextStyle"
            :color="buttonColorClass"
            :class="buttonRecommendedClass()"
          >
            <v-icon>
              <slot name="iconName">
                {{ iconAdd }}
              </slot>
            </v-icon>
          </v-btn>
        </template>

        <!-- Tooltip -->
        <template #default>
          <slot name="toolTip">
            <span>Add More Fields</span>
          </slot>
        </template>
      </v-tooltip>
    </template>

    <slot name="default"></slot>
  </v-menu>
</template>

<script>
// design
import { iconAdd } from "@/design/icon/iconConst";

// mixins
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "MoreFieldsPopoverMenu",
  inheritAttrs: false,
  mixins: [buttonSettingsMixin, slottableMixin],
  data() {
    return {
      iconAdd: iconAdd
    };
  }
};
</script>
